import React, { useEffect, useState } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

interface Props {
  data: any
  placement?: 'top' | 'bottom'
  item?: number
}

const GenerateRandomBanner: React.FC<Props> = ({
  data,
  placement = 'top',
  item,
}) => {
  const [bannerNumber, setBannerNumber] = useState(0)

  const randomGenerator = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  const { allFile } = data
  const { edges } = allFile
  const minCount = placement === 'top' ? 0 : Math.ceil(edges.length / 2)
  const maxCount =
    placement === 'top' ? Math.ceil(edges.length / 2) : edges.length

  useEffect(() => {
    if (item) {
      setBannerNumber(item !== undefined ? item : 0)
    } else {
      const randomPosition = randomGenerator(minCount, maxCount - 1)
      setBannerNumber(randomPosition)
    }
  }, [item, maxCount, minCount])

  return (
    <>
      <GatsbyImage
        image={edges[bannerNumber].node.childImageSharp.gatsbyImageData}
        alt="popup banner"
      />
    </>
  )
}

export default GenerateRandomBanner
