import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import GenerateRandomBanner from '../GenerateRandomBanner'

interface Props {
  placement?: 'top' | 'bottom'
  item?: number
}

const RummyRandomBanner: React.FC<Props> = ({ placement = 'top', item }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(jpeg)|(png)/" }
          relativeDirectory: { eq: "level2-automated-banners/rummy" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  `)

  return <GenerateRandomBanner data={data} placement={placement} item={item} />
}

export default RummyRandomBanner
