import React, { useEffect, useState } from 'react'
import PokerRandomBanner from '../../templates/real-money-games/random-banners/PokerRandomBanner'
import RummyRandomBanner from '../../templates/real-money-games/random-banners/RummyRandomBanner'
import CouponItem from './CouponItem'

interface Props {
  couponData: any[]
  megaCouponData?: any[]
  couponName: string
  couponCat: string
  openPopup: () => void
}

const CouponItems: React.FC<Props> = ({
  couponData,
  couponName,
  megaCouponData,
  openPopup,
  couponCat,
}) => {
  const [offerPos, setOfferPos] = useState(-1)

  useEffect(() => {
    // show the Mega coupon if more than 4 coupons found on this page
    if (couponData.length > 3) {
      setOfferPos(Math.floor(couponData.length / 2))
    }
  }, [couponData.length])

  const megaCouponItem = megaCouponData?.length ? (
    <>
      <CouponItem
        key={999}
        couponSlug="getmega"
        title={megaCouponData[1]}
        desc={megaCouponData[2]}
        details={megaCouponData[3]}
        code={megaCouponData[0]}
        couponName={couponName}
        megaCoupon
      />
    </>
  ) : null

  return (
    <>
      {couponData.length ? (
        couponData.map(
          (
            item: {
              id: React.Key | null | undefined
              couponTitle: string
              couponDesc: string
              couponDetails: string
              couponCode: string
            },
            index
          ) => (
            <>
              {index === 1 && (
                <div
                  aria-hidden="true"
                  role="button"
                  onClick={openPopup}
                  onKeyDown={openPopup}
                  className="coupon-banner"
                >
                  {couponCat === 'display/Rummy' ? (
                    <RummyRandomBanner />
                  ) : (
                    <PokerRandomBanner />
                  )}
                </div>
              )}
              {offerPos > 0 && index === offerPos + 1 && megaCouponItem}
              <CouponItem
                key={item.id}
                couponSlug={item.id}
                title={item.couponTitle}
                desc={item.couponDesc}
                details={item.couponDetails}
                code={item.couponCode}
                couponName={couponName}
              />
            </>
          )
        )
      ) : (
        <p className="muted-text">
          No promos at the moment, please check back later.
        </p>
      )}
    </>
  )
}

export default CouponItems
