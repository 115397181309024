import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import {
  generateBlogPostingSchema,
  generateBreadcrumbsSchema,
  generateFaqSchema,
} from '../../lib/generate-schema'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import getBreadcrumbs from '../getBreadcrumbs'
import './Template.scss'
import CouponItems from '../../components/Coupons/CouponItems'
import PopupCoupon from '../../components/Coupons/PopupCoupon'
import HomeFAQs from '../../components/Index/HomeFAQs/HomeFAQs'
import getFaqsCMS from '../../utils/getFaqsCMS'
import PopupDownload from '../../components/PopupDownload/PopupDownload'
import {
  pokerOneLinksPokerPage,
  rummyOneLinksRummyPage,
} from '../../components/one-links'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { modifyHtmlBeforeRendering } from '../../utils/modifyATag'

const CouponsTemplate: React.FC = (context: any) => {
  const { data, pageContext } = context
  const post = data.currPost
  post.html = modifyHtmlBeforeRendering(post.html)
  const relatedPost = data.likePosts.edges

  const { prefix, oneLink } = pageContext

  const breadcrumbs: Breadcrumb[] = getBreadcrumbs(prefix, post)

  const [loaded, setLoaded] = useState(false)

  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const [showMegaoffer, setShowMegaoffer] = useState(false)

  const [showContent, setShowContent] = useState(false)

  const [activeTab, setActiveTab] = useState('all')

  const toggleDetails = () => {
    setShowContent(current => !current)
  }

  const toggleTab = (active: React.SetStateAction<string>) => {
    setActiveTab(active)
  }

  const [couponData, setCouponData] = useState<any | null>([])
  const [couponItemsData, setCouponItemsData] = useState<any | null>([])
  const [offerItemsData, setOfferItemsData] = useState<any | null>([])

  const [popupPage, setPopupPage] = useState(false)
  const [megaPopup, setMegaPopup] = useState(false)

  const [couponName, setCouponName] = useState<any | null>([])
  const [couponAbout, setCouponAbout] = useState<any | null>([])
  const [couponContent, setCouponContent] = useState<any | null>([])
  const [couponWebsite, setCouponWebsite] = useState<any | null>([])
  const [popupDesc, setPopupDesc] = useState<any | null>([])

  const faqDataCMS = loaded ? getFaqsCMS(post) : undefined

  const [defaultFaqs, setDefaultFaqs] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ default: dfaq }) => {
      return setDefaultFaqs(dfaq.slice(0, 5))
    })
  }, [])

  const faqsList = faqDataCMS?.length ? faqDataCMS : defaultFaqs

  const [totalCouponLength, setTotalCouponLength] = useState(0)
  const [couponLength, setCouponLength] = useState(0)
  const [offerLength, setOfferLength] = useState(0)

  const [urlCouponCode, SetUrlCouponCode] = useState<any | null>([])

  const [pageUrl, SetPageUrl] = useState<any | null>([])
  const [relatedCoupons, setRelatedCoupons] = useState<any | null>([])

  const [couponType, setCouponType] = useState('')

  const [getmegaCoupondata, setGetmegaCoupondata] = useState<any | null>([])
  const [getmegaOffertext, setGetmegaOffertext] = useState<any | null>([])

  let rummyFormVisible = false
  let pokerFormVisible = false

  if (prefix.includes('poker')) {
    pokerFormVisible = true
  }

  if (prefix.includes('rummy')) {
    rummyFormVisible = true
  }

  const handlePlay = () => {
    setIsPopupOpen(true)
  }

  const popupCode = isPopupOpen ? (
    <PopupDownload
      close={() => {
        setIsPopupOpen(false)
      }}
      gameName="coupons"
      apkLink={oneLink}
      showPokerOnly={pokerFormVisible}
      showRummyOnly={rummyFormVisible}
      pokerLink={pokerFormVisible ? pokerOneLinksPokerPage : undefined}
      rummyLink={rummyFormVisible ? rummyOneLinksRummyPage : undefined}
    />
  ) : null

  useEffect(() => {
    if (window.location.href.indexOf('viewdetails') !== -1) {
      const url = window.location.href
      SetUrlCouponCode(url.split('=').pop())
      setPopupPage(true)
      if (urlCouponCode === 'getmega') {
        setMegaPopup(true)
      }
    }
    const { html } = post

    SetPageUrl(`${prefix}/${post.slug}/`)

    const tableContents = document.createElement('div')
    tableContents.innerHTML = `${html}`

    const tableCoupon = tableContents?.querySelector('table#coupons')
    const tableContent = tableContents?.querySelector('table#coupon-content')
    const getmegaCoupon = tableContents?.querySelector('table#coupon-getmega')
    const getmegaOffer = tableContents?.querySelector('table#offer-getmega')

    if (tableCoupon) {
      // setting content
      const rows = tableCoupon.getElementsByTagName('tr')
      const coupons = []
      for (let i = 1; i < rows.length; i += 1) {
        const tds = rows[i].getElementsByTagName('td')
        coupons.push({
          id: i,
          couponCode: tds[0].textContent,
          couponTitle: tds[1].textContent,
          couponDesc: tds[2].textContent,
          couponDetails: tds[3].textContent,
        })
      }
      setCouponData(coupons)
      setCouponItemsData(
        coupons.filter(item => item.couponCode?.toLowerCase() !== 'offer')
      )
      setOfferItemsData(
        coupons.filter(item => item.couponCode?.toLowerCase() === 'offer')
      )
      const totalCoupons = coupons.length
      const checkOffers = coupons.filter(
        item => item.couponCode?.toLowerCase() === 'offer'
      )
      setCouponLength(totalCoupons - checkOffers.length)
      setOfferLength(checkOffers.length)
      setTotalCouponLength(totalCoupons)
    }

    if (tableContent) {
      // setting content
      const rows = tableContent.getElementsByTagName('tr')
      const content = []

      for (let i = 1; i < rows.length; i += 1) {
        const tds = rows[i].getElementsByTagName('td')
        content.push({
          id: i,
          tdData: tds[1],
        })
      }

      setCouponName(content[0].tdData.textContent)
      setCouponAbout(content[1].tdData.innerHTML)
      setCouponContent(content[2].tdData.innerHTML)
      setCouponWebsite(content[3].tdData.textContent)
      setPopupDesc(content[5].tdData.innerHTML)
    }

    if (getmegaCoupon) {
      // setting content
      const rows = getmegaCoupon.getElementsByTagName('tr')
      const getmegaCoupons = []

      for (let i = 1; i < rows.length; i += 1) {
        const tds = rows[i].getElementsByTagName('td')
        getmegaCoupons.push(tds[1].innerHTML)
      }
      setGetmegaCoupondata(getmegaCoupons)
    }

    if (getmegaOffer) {
      // setting content
      const rows = getmegaOffer.getElementsByTagName('tr')
      if (rows.length) {
        setGetmegaOffertext(rows[1].getElementsByTagName('td')[1].textContent)
      }
      setShowMegaoffer(true)
    }

    const tag = post.tags.find((obj: { name: string }) => {
      return obj.name === 'display/Poker' || obj.name === 'display/Rummy'
    })

    if (tag) {
      setCouponType(tag.name)
    }

    setRelatedCoupons(
      couponType !== ''
        ? relatedPost
            .filter((e: { node: { tags: any[] } }) =>
              e.node.tags.some(obj => obj.name === couponType)
            )
            .slice(0, 12)
            .map((edge: any) => {
              return (
                <div key={edge.node.slug} className="similar-item">
                  <Link
                    to={`${prefix}/${edge.node.slug}/`}
                    title={edge.node.title}
                  >
                    {edge.node.feature_image ? (
                      <div className="similar-image">
                        <img
                          width={200}
                          height={200}
                          src={edge.node.feature_image}
                          alt={edge.node.title}
                        />
                      </div>
                    ) : (
                      <h5>{edge.node.title}</h5>
                    )}
                  </Link>
                </div>
              )
            })
        : null
    )

    setLoaded(true)
  }, [post, loaded, prefix, couponType, relatedPost, urlCouponCode])

  return loaded ? (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={oneLink}
      showPokerForm={pokerFormVisible}
      showRummyForm={rummyFormVisible}
      pokerOneLink={pokerFormVisible ? pokerOneLinksPokerPage : undefined}
      rummyOneLink={rummyFormVisible ? rummyOneLinksRummyPage : undefined}
    >
      <SEO
        blogPostingSchema={generateBlogPostingSchema({
          articleBody: post.html,
          author: post.primary_author.name,
          createdAt: post.created_at,
          description: post.meta_description,
          image: post.feature_image,
          modifiedAt: post.updated_at,
          publishedAt: post.published_at,
          title: `${post.title} | Mega}`,
          url: `${data.site.siteMetadata.siteUrl + prefix}/${post.slug}`,
        })}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        description={post.meta_description}
        title={`${post.title} | Mega`}
        url={`${data.site.siteMetadata.siteUrl + prefix}/${post.slug}`}
        faqSchema={generateFaqSchema(faqsList)}
      />
      <Helmet>
        <link rel="preconnect" href="https://getmega.ghost.io" />
        <link rel="dns-prefetch" href="https://getmega.ghost.io" />
      </Helmet>

      {rummyFormVisible && (
        <Helmet>
          <script>
            {`
            document.addEventListener('DOMContentLoaded', () => {
              /** init gtm after 1500 seconds - this could be adjusted */
              setTimeout(initGTM, 1500);
            });
            document.addEventListener('scroll', initGTMOnEvent);
            document.addEventListener('mousemove', initGTMOnEvent);
            document.addEventListener('touchstart', initGTMOnEvent);
            function initGTMOnEvent(event) {
              initGTM();
              event.currentTarget.removeEventListener(event.type, initGTMOnEvent); // remove the event listener that got triggered
            }
            function initGTM() {
              if (window.gtmDidInit) {
                return false;
              }
              window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.
              const script = document.createElement('script');
              script.type = 'text/javascript';
              script.async = true;
              // ensure PageViews is always tracked (on script load)
              script.onload = () => {
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
                  gtag('config', 'AW-718511341');
              };
              script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-718511341';
              document.head.appendChild(script);
            }
          `}
          </script>
        </Helmet>
      )}

      <article id="Post" className="coupon-blog-post">
        <Container>
          <div className="coupon-page-wrap">
            {popupPage ? (
              <PopupCoupon
                couponData={couponData}
                couponName={couponName}
                couponLogo={post.feature_image}
                website={couponWebsite}
                popupDesc={popupDesc}
                urlCouponCode={urlCouponCode}
                similarCoupons={relatedCoupons}
                pageUrl={pageUrl}
                megaCouponData={getmegaCoupondata}
                megacouponPage={megaPopup}
              />
            ) : (
              <>
                <div className="copuon-top-sec">
                  <div className="coupon-logo">
                    <img src={post.feature_image} alt={post.title} />
                  </div>
                  <div className="coupon-head-sec">
                    <>
                      <h1>{post.title}</h1>
                      <div className="coupon-meta">
                        {totalCouponLength} Verified Coupons &amp; Offers
                      </div>
                      <div className="coupons-tab">
                        <button
                          className={`tab-item ${
                            activeTab === 'all' ? 'active' : ''
                          }`}
                          type="button"
                          onClick={() => {
                            toggleTab('all')
                          }}
                        >
                          All ({totalCouponLength})
                        </button>
                        <button
                          className={`tab-item ${
                            activeTab === 'coupons' ? 'active' : ''
                          }`}
                          type="button"
                          onClick={() => {
                            toggleTab('coupons')
                          }}
                        >
                          Coupons ({couponLength})
                        </button>
                        <button
                          className={`tab-item ${
                            activeTab === 'offers' ? 'active' : ''
                          }`}
                          type="button"
                          onClick={() => {
                            toggleTab('offers')
                          }}
                        >
                          Offers ({offerLength})
                        </button>
                      </div>
                    </>
                  </div>
                </div>

                <div className="coupon-bottom-sec">
                  <div
                    className={`coupon-bottom-left ${
                      showContent ? 'show-content' : ''
                    }`}
                  >
                    <div
                      className="coupon-about"
                      onClick={toggleDetails}
                      onKeyDown={toggleDetails}
                      role="button"
                      tabIndex={0}
                    >
                      About {couponName}
                      <span>
                        {showContent ? (
                          <StaticImage
                            loading="eager"
                            src="../../images/coupons/angle-up.svg"
                            alt="dropdown"
                            width={16}
                          />
                        ) : (
                          <StaticImage
                            loading="eager"
                            src="../../images/coupons/angle-down.svg"
                            alt="dropdown"
                            width={16}
                          />
                        )}
                      </span>
                    </div>
                    <div
                      className="coupon-about-text"
                      dangerouslySetInnerHTML={{ __html: couponAbout }}
                    />
                  </div>
                  <div className="coupon-bottom-right">
                    {activeTab === 'all' && (
                      <CouponItems
                        couponData={couponData}
                        couponName={couponName}
                        megaCouponData={getmegaCoupondata}
                        openPopup={handlePlay}
                        couponCat={couponType}
                      />
                    )}
                    {activeTab === 'coupons' && (
                      <CouponItems
                        couponData={couponItemsData}
                        couponName={couponName}
                        megaCouponData={getmegaCoupondata}
                        openPopup={handlePlay}
                        couponCat={couponType}
                      />
                    )}
                    {activeTab === 'offers' && (
                      <CouponItems
                        couponData={offerItemsData}
                        couponName={couponName}
                        megaCouponData={getmegaCoupondata}
                        openPopup={handlePlay}
                        couponCat={couponType}
                      />
                    )}
                    {showMegaoffer && (
                      <>
                        <div className="mega-offer-block">
                          <div className="mega-offer-left">
                            <StaticImage
                              loading="eager"
                              src="../../images/coupons/logo-small.png"
                              alt="getmega"
                              width={55}
                            />
                            <span>{getmegaOffertext}</span>
                          </div>
                          <div className="mega-offer-right">
                            <button
                              className="offer-btn"
                              type="button"
                              onClick={handlePlay}
                            >
                              Play Now
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    <div
                      className="coupon-content-middle"
                      dangerouslySetInnerHTML={{ __html: couponContent }}
                    />
                    <HomeFAQs
                      hideAllFaqsLink
                      faqTitle="FAQs:"
                      faqs={faqsList}
                      color="#ffffff"
                    />
                    <h2 className="similar-heading">Similar Stores</h2>
                    {relatedCoupons && relatedCoupons.length ? (
                      <div className="similar-coupons-wrap">
                        {relatedCoupons}
                      </div>
                    ) : (
                      <div className="no-posts">No Similar Coupons Found</div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </Container>
      </article>
      {popupCode}
    </Layout>
  ) : null
}

export default CouponsTemplate

export const postQuery = graphql`
  query ($slug: String!) {
    currPost: ghostPost(slug: { eq: $slug }) {
      title
      slug
      feature_image
      html
      excerpt
      meta_title
      meta_description
      published_at
      created_at
      reading_time
      updated_at(formatString: "MMMM DD, YYYY")
      custom_template
      tags {
        slug
        name
      }
      primary_author {
        name
        slug
      }
    }
    likePosts: allGhostPost(
      filter: {
        slug: { ne: $slug }
        tags: { elemMatch: { name: { eq: "#coupons" } } }
      }
    ) {
      edges {
        node {
          id
          feature_image
          slug
          title
          tags {
            slug
            name
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
