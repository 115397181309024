import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import './PopupCoupon.scss'

interface Props {
  couponData?: any[]
  megaCouponData?: any[]
  megacouponPage?: boolean
  website: string
  popupDesc: string
  couponLogo: string
  couponName: string
  urlCouponCode: string
  pageUrl: string
  similarCoupons: any[]
}

const PopupCoupon: React.FC<Props> = ({
  couponData,
  website,
  popupDesc,
  couponName,
  couponLogo,
  urlCouponCode,
  similarCoupons,
  pageUrl,
  megacouponPage,
  megaCouponData,
}) => {
  const [textCopied, setTextCopied] = useState(false)

  const copyCode = (code: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    typeof window !== 'undefined' && navigator.clipboard.writeText(code)
    setTextCopied(true)
  }

  const offerBlock = (
    <>
      <div className="popup-info">
        Click the button below to visit the website and avail the offer.
      </div>
      <div className="popup-code">
        <span>Offer Activated</span>
      </div>
    </>
  )

  const couponBlock = (code: string) => (
    <>
      <div className="popup-info">
        Copy the below coupon &amp; apply it at the time of payment
      </div>
      <div className="popup-code">
        <span>{code}</span>
        <button
          type="button"
          onClick={() => {
            copyCode(code)
          }}
        >
          {textCopied ? 'code copied' : 'copy code'}
        </button>
      </div>
    </>
  )

  const popupLinkBlock = (link: string) => (
    <div className="popup-link">
      <a href={link} target="_blank" rel="noopener noreferrer">
        Visit {megacouponPage ? 'Mega' : couponName}
      </a>
    </div>
  )

  const popupBackBlock = (couponCode: string) => (
    <div className="popup-back">
      <a href={pageUrl}>
        {megacouponPage ? (
          'Go back to previous page'
        ) : (
          <>
            Go back to view all {couponName}{' '}
            {couponCode?.toLowerCase() === 'offer' ? 'offers' : 'coupons'}
          </>
        )}
      </a>
    </div>
  )

  const descriptionBlock = (desc: any) => (
    <div className="popup-desc-sec">
      <h5>Description</h5>
      <div
        className="popup-desc-content"
        dangerouslySetInnerHTML={{ __html: desc }}
      />
    </div>
  )

  const similarContent = (
    <div className="popup-related">
      {similarCoupons && similarCoupons.length ? (
        <>
          <div className="similar-heading">Similar Stores:</div>
          <div className="similar-coupons-wrap popup-similar-wrap">
            {similarCoupons}
          </div>
        </>
      ) : (
        <div className="no-posts">No Similar Coupons Found</div>
      )}
    </div>
  )

  return (
    <>
      {megacouponPage
        ? megaCouponData?.length && (
            <div className="popup-section">
              <div className="popup-logo">
                <StaticImage
                  loading="eager"
                  src="../../images/coupons/logo-black.png"
                  alt="getmega"
                  width={98}
                />
              </div>
              <div className="popup-title">{megaCouponData[1]}</div>
              {megaCouponData[0]?.toLowerCase() === 'offer'
                ? offerBlock
                : couponBlock(megaCouponData[0])}
              {popupLinkBlock(megaCouponData[5])}
              {popupBackBlock(megaCouponData[0])}
              {descriptionBlock(megaCouponData[4])}
              {similarContent}
            </div>
          )
        : couponData?.length &&
          couponData
            .filter(item => item.id === parseInt(urlCouponCode, 10))
            .map(
              (item: {
                id: React.Key | null | undefined
                couponTitle: string
                couponDesc: string
                couponValid: string
                couponDetails: string
                couponCode: string
              }) => (
                <div key={item.id} className="popup-section">
                  <div className="popup-logo">
                    <img src={couponLogo} alt={couponName} />
                  </div>
                  <div className="popup-title">{item.couponDesc}</div>
                  {item.couponCode?.toLowerCase() === 'offer'
                    ? offerBlock
                    : couponBlock(item.couponCode)}
                  {popupLinkBlock(website)}
                  {popupBackBlock(item.couponCode)}

                  {descriptionBlock(popupDesc)}
                  {similarContent}
                </div>
              )
            )}
    </>
  )
}

export default PopupCoupon
