import React, { useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import './CouponItem.scss'

interface Props {
  title: string
  desc: string
  details: string
  code: string
  couponName: string
  couponSlug: any
  megaCoupon?: boolean
}

const CouponItem: React.FC<Props> = ({
  title,
  desc,
  details,
  code,
  couponName,
  couponSlug,
  megaCoupon,
}) => {
  const [showDetails, setShowDetails] = useState(false)

  const toggleDetails = () => {
    setShowDetails(current => !current)
  }

  const [showShare, setShowShare] = useState(false)

  const toggleShare = () => {
    setShowShare(current => !current)
  }

  const couponUrl = `${window.location.href}?viewdetails=${couponSlug}`

  const openInNewTab = () => {
    // eslint-disable-next-line func-names
    setTimeout(function () {
      window.location.href = 'https://www.getmega.com'
    }, 1000)
    window.open(couponUrl)
  }

  const copyLinkFunction = () => {
    navigator.clipboard.writeText(couponUrl)
  }

  return (
    <div className={`coupon-item ${megaCoupon ? 'mega-coupon' : ''}`}>
      {megaCoupon && (
        <div className="ci-logo">
          <StaticImage
            loading="eager"
            src="../../images/coupons/logo-black.png"
            alt="getmega"
            width={98}
          />
        </div>
      )}
      <div className="ci-top">
        <div className="ci-top-left">
          <div className="coupon-offer">{title}</div>
          <div className="coupon-short-desc">{desc}</div>
        </div>
        <div className="ci-top-right">
          <div className="ci-verified">
            <StaticImage
              loading="eager"
              src="../../images/coupons/check.svg"
              alt="verified"
              width={15}
            />
            <span>Verified</span>
          </div>
          <div className="ci-button">
            <button type="button" className="ci-btn" onClick={openInNewTab}>
              {code.toLowerCase() === 'offer' ? (
                <>
                  View Offer <span>&nbsp; Details</span>
                </>
              ) : (
                <>
                  View Coupon <span>&nbsp; Code</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="ci-bottom">
        <div
          className="ci-bottom-details"
          onClick={toggleDetails}
          onKeyDown={toggleDetails}
          role="button"
          tabIndex={0}
        >
          Show Details
        </div>
        <div className="ci-bottom-share">
          <div
            onClick={toggleShare}
            onKeyDown={toggleShare}
            tabIndex={0}
            role="button"
          >
            <StaticImage
              loading="eager"
              src="../../images/coupons/share.svg"
              alt="share"
              width={24}
            />
          </div>
          {showShare && (
            <div className="share-wrap">
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://twitter.com/share?text=Check out this latest coupon from ${couponName} on Mega&url=${encodeURI(
                  couponUrl
                )}&via=GetMegaApp`}
                title="Share on Twitter"
                className="share-item"
              >
                <StaticImage
                  loading="eager"
                  src="../../images/coupons/twitter.png"
                  alt="twitter"
                  width={20}
                  layout="constrained"
                />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href={`https://api.whatsapp.com/send?text=${encodeURI(
                  couponUrl
                )}`}
                title="Share via WhatsApp"
                data-action="share/whatsapp/share"
                className="share-item"
              >
                <StaticImage
                  loading="eager"
                  src="../../images/coupons/whatsapp.png"
                  alt="whatsapp"
                  width={20}
                  layout="constrained"
                />
              </a>
              <div
                role="button"
                tabIndex={0}
                onClick={() => copyLinkFunction()}
                onKeyDown={() => copyLinkFunction()}
                className="share-item"
                title="Copy Link"
              >
                <StaticImage
                  loading="eager"
                  src="../../images/coupons/copy.png"
                  alt="copy"
                  width={20}
                  layout="constrained"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {showDetails && <div className="ci-bottom-more-details">{details}</div>}
    </div>
  )
}

export default CouponItem
