function getFaqsCMS(post: any) {
  const { html } = post
  const postContent = document.createElement('div')
  postContent.innerHTML = `${html}`

  const faqDiv = postContent?.querySelectorAll('#blog-faqs')[0]

  const faqTable = faqDiv?.nextElementSibling
  const blogFaqsCMS = []
  if (faqTable) {
    const rows = faqTable.getElementsByTagName('tr')

    for (let i = 1; i < rows.length; i += 1) {
      const tds = rows[i].getElementsByTagName('td')
      if (tds[0].childNodes.length && tds[1].childNodes.length) {
        blogFaqsCMS.push({
          question: tds[0].textContent,
          answer: [tds[1].innerHTML],
        })
      }
    }
  }
  return blogFaqsCMS
}

export default getFaqsCMS
